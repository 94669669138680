<template>
    <v-container fluid>
        <v-row>
            <v-col cols="4" class="mx-auto mt-15">
                <v-card flat class="py-15 text-center">
                        <h2 class="green--text pb-2">Thank you.</h2>
                        <p>You are now part of this game</p>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name:'SuccessPage',


}
</script>

<style>
.v-speed-dial__list{
    left:auto !important;
    right:50px !important
}
.mt-70{
    margin-top:10%;
}
</style>